var classList = require('dom-classlist');
var gsap = require('gsap');
var pubsub = require('pubsub');
var serialize = require('form-serialize');
var xhr = require('xhr');


module.exports = function () {
	
	var _nlForm = document.querySelector('#newsletter')
	if(!_nlForm) return;

	var _btSubmit = _nlForm.querySelector('input[type="submit"]')
	,	_inputs = _nlForm.querySelectorAll('input[type="text"]')
	,	_inputInfo = _nlForm.querySelector('input[name="infos"]')
	,	_confirmField = _nlForm.querySelector('.intro')
	,	_msgReceptor = _nlForm.querySelector('.intro')
	,	originalMsg = _msgReceptor.innerHTML
	,	isLoading = false
	,	to

	addHandlers()

	function addHandlers() {
		_btSubmit.addEventListener('click', clickSubmit)
	}
	
	function clickSubmit(e) {
		e.preventDefault();
		//if(_input.getAttribute('value') =='') return;
		submitForm();
	}
	

	function submitForm() {

		if(isLoading) return;
		isLoading = true;

		classList(_btSubmit).remove('ok')
		classList(_btSubmit).remove('ko')
		//classList(_confirmField).remove('active')
		Array.prototype.forEach.call(_inputs, function(input){
			classList(input).remove('error')
		})
			
	    var currentUrl = _nlForm.action
	    ,   reqContent = serialize(_nlForm);
	    ;
	    //console.log(_inputInfo.value);
	    if(_inputInfo.value =='') reqContent +=('&infos='+_inputInfo.value);
	   
	    //return;
	    //reqContent += 'todo=contact';
	    //console.log(reqContent);



	    TweenMax.to(_btSubmit, .2, {autoAlpha:0, onComplete: function(){
	       
	        xhr({
	                url: currentUrl
	            ,   headers:{"Content-Type": "application/x-www-form-urlencoded"}
	            ,   method:'POST'
	            ,   body: reqContent
	            }
	            ,   function (err, resp, body) {
	            	
	                    if(err == null){
	                    	isLoading = false;
	                        var JSONbody = JSON.parse(body);
	                        if(JSONbody == null) return;
	                        Array.prototype.forEach.call(JSONbody, function(el, i){
	                        	//console.log(el);
								if(el.level == 'error'){
		                        	//classList(_nlForm.querySelector('#'+el.field)).add('error')
		                        	classList(_btSubmit).add('ko');
		                        	classList(_confirmField).add('ko');
		                        	_confirmField.innerHTML = el.msg;
		                        	clearTimeout(to)
		                        	to = setTimeout(function(){
		                        		classList(_btSubmit).remove('ko');
		                        		classList(_confirmField).remove('ko');
		                        		_confirmField.innerHTML = originalMsg;
		                        		TweenMax.to(_btSubmit, .2, {autoAlpha: 1})
		                        	}, 1500)
		                        } else {
		                        	classList(_btSubmit).add('ok')
		                        	if(el.field == "btn")_confirmField.setAttribute("value", el.msg)
		                        	if(el.field == "msg")_confirmField.innerHTML = el.msg;
		                        	classList(_confirmField).add('ok');

			                        Array.prototype.forEach.call(_inputs, function(input){
			                        	input.disabled = true;
			                        	input.readOnly = true;
			                        })
		                        }
		                        
	                        });                        
	                        
	                        
	                        //classList(_confirmField).add('active')


	                    } else {
	                        //_loaderMoreArticles.classList.remove('active')
	                        console.log(err);
	                    }
	                }
	            
	            )

	        
	    }})
	    
	    
	}
}
