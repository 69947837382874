var classList = require('dom-classlist');
var gsap = require('gsap');
var pubsub = require('pubsub');

module.exports = function () { 
	var _player = document.querySelector('#player')
	if(!_player) return;
	var _viewItenHeader = document.querySelector('.view-item_header')
	,	_videoPoster = _viewItenHeader.querySelector('.video-poster')
    ,   _pictosVideo = document.querySelectorAll('.picto-video')
	,	_content = _viewItenHeader.querySelector('.view-item_header-content')
	,	_infos = _viewItenHeader.querySelector('.view-item_header-infos')
	,	tl = new TimelineLite({paused:true})
	,	speed = .3
	;

	tl.add(TweenMax.to(_infos, speed, {autoAlpha:0}))
	tl.add(TweenMax.to(_content, speed, {autoAlpha:0}))
	tl.add(TweenMax.to(_videoPoster, speed, {autoAlpha:0}), speed*2.5)

	var videoID = getYoutubeID(_player.getAttribute('data-src'))
	var youtubePlayer = new YT.Player('player', {
     	height: '100%',
     	width: '100%',
     	videoId: videoID,
     	playerVars: {
     		'modestbranding': 1,
	     	'rel': 0,
	     	'showinfo': 0,
	     	'suggestedQuality': 'highres',
     	},
     	suggestedQuality: 'highres',
     	events: {
        	'onReady': onPlayerReady,
        	'onStateChange': onPlayerStateChange
      	}
    });

    function onPlayerReady(e){
    	//console.log('onPlayerReady');
        var _player = e.target
        Array.prototype.forEach.call(_pictosVideo, function(el) {
            el.addEventListener('click', function(){
                _player.playVideo();
            })
        })
        
    }

    function onPlayerStateChange(event){
    	//console.log('onPlayerStateChange', event);
    	if(event.data == YT.PlayerState.PLAYING){
    		displayWebsiteContext(false)
    	} else if(/*event.data == YT.PlayerState.PAUSED ||*/ event.data == YT.PlayerState.ENDED){
    		displayWebsiteContext(true)
    	}
    }

    function displayWebsiteContext(visible){
    	/*var opacity = visible ? 1 : 0
    	,	speed = .3
    	;
    	TweenMax.to(_videoPoster, speed, {autoAlpha:opacity})
    	TweenMax.to(_content, speed, {autoAlpha:opacity})
    	TweenMax.to(_infos, speed, {autoAlpha:opacity})*/
    	if(visible){
    		tl.reverse()
    	} else {
    		tl.play()
    	}
    }

	function getYoutubeID(url){
		var ID = '';
		  url = url.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
		  if(url[2] !== undefined) {
		    ID = url[2].split(/[^0-9a-z_\-]/i);
		    ID = ID[0];
		  }
		  else {
		    ID = url;
		  }
		  return ID;
	}

}

