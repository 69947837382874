var gsap = require('gsap');
var pubsub 	= require('pubsub');

module.exports = function () {
	//var _imgTags = [].slice.call(document.querySelectorAll('img'))
	var	_visuals = [].slice.call(document.querySelectorAll( '.bg, .view-item_header, .visual'))
	,	_imgTags = [].slice.call(document.querySelectorAll( 'img'))
	,	imagesToLoad = []
	,	indexLoaded = 0
	,	loadedPercent = 0
	,	nbImages
	,	_loader = document.querySelector('.loader')
	,	_counter = _loader.querySelector('.counter')
	,   fill_duration = 1.2
	;


	_imgTags.forEach(function(el) {
	   //console.log(getSrc(el)); 
	   imagesToLoad.push(getSrc(el))
	});
	_visuals.forEach(function(el) {
	   imagesToLoad.push(getSrc(el))
	});

	nbImages = imagesToLoad.length

	function preloadVisual(index){

		var img = new Image()
		,	imgsrc = imagesToLoad[index]
		if(imgsrc ==""){
			loadNext();
		} else {
			img.onload = function(){
				loadNext()
			}
			img.onerror = function(error){
				loadNext()
			}
			img.src =imgsrc
		}
	}

	function loadNext(){
		indexLoaded++
		animate(getPercent(indexLoaded))

		if(indexLoaded < nbImages){
			preloadVisual(indexLoaded)
		} else {
			TweenMax.to(_loader, .3, {autoAlpha: 0, delay:fill_duration, onComplete:function(){
				_loader.parentNode.removeChild(_loader)
			}})
		}
	}
	preloadVisual(indexLoaded)

	function getPercent(index){
		var percent = 0;
		percent = Math.round(index *100 / nbImages)
		return percent;
	}

	function getSrc(el){
		var src ='';
		if(el.tagName === 'IMG'){
			src = el.getAttribute('src')
		} else {
			var bg = el.style.backgroundImage;
        	if (bg !== '') src = bg.replace('url(','').replace(')','').replace('"','').replace('"','');//bg.split(/[()]+/)[1];
		}
		return src;
	}

	function animate(percentage){
		if(_counter) _counter.innerHTML = percentage + '%'
		//console.log('animate', percentage);
		//tl.fromTo( fill_path, fill_duration, { drawSVG: '0' }, { drawSVG: percentage+'%' }, fill_position )
		//tl.to( fill_path, fill_duration, { drawSVG: percentage+'%'  }, fill_position );
	} 
	 
	
}