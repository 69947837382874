var classList = require('dom-classlist');
var gsap = require('gsap');
var pubsub = require('pubsub');

// Variables
var canToggle = true,
    opened = false,
    hoverTimline,
    _navMain = document.querySelector('.mobile-nav-main'),
    _navMainItems = _navMain.querySelectorAll('li'),
    _sub = _navMain.querySelector('.mobile-nav-main__sub-list'),
    _menuBurger = document.querySelector('.burger-menu'),
    _logoText = document.querySelector('.main-logo-text');



pubsub.on('window:scroll', function(scrollY){
    if(scrollY > 70){
       classList(_logoText).add('hidden')
    } else {
        classList(_logoText).remove('hidden')
    }
})

// Menu Main TimeLine
var tlMainMenu = new TimelineLite({paused:true});

tlMainMenu.add( TweenMax.to(_navMain, 0.6, { opacity : 1, ease: Power2.easeInOut }));
tlMainMenu.add( TweenMax.staggerFrom(_navMainItems, 0.3, { y:"+=100", opacity: 0,  ease: Power2.easeInOut }, 0.06), "-=0.55");

// Add class hidden (visibility:hidden) to navMain when close menu annimation ended
tlMainMenu.eventCallback("onReverseComplete", function() {
    classList(_navMain).add('hidden');
});

// Menu Main TimeLine
TweenLite.defaultEase = Power1.easeOut;


_menuBurger.addEventListener('click', function(e) {
    e.preventDefault();

    if(!classList(this).contains('active')){
        classList(_navMain).remove('hidden');
        openMainMenu(); 
    }else{
        closeMainMenu();
    }

    classList(this).toggle('active');
    canToggle = false;
});


document.onkeyup = function(e) {
    var open = classList(_menuBurger).contains('active');
    if (e.keyCode == 27 && open) {
        closeMainMenu();
    }
};


function openMainMenu() {
    tlMainMenu.play();
    pubsub.emit('body:noscroll')
    // Remove scroll for touch devices
    document.ontouchmove = function(e){ 
        e.preventDefault(); 
    }
    opened = true;
}

function closeMainMenu() {
   
    tlMainMenu.reverse();
    
    tlMainMenu.reverse();
    pubsub.emit('body:scroll')
    // Readd scroll for touch devices
    document.ontouchmove = function(e){ 
    }
    opened = false;
}