var classList = require('dom-classlist');
var gsap = require('gsap');
var pubsub = require('pubsub');

module.exports = function () { 
	let _players = document.querySelectorAll('.slider-player')
	if(!_players) return;


}

