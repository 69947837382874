(function initFB(){
	var _btFB = document.querySelector('.share .icon-facebook');
	if(!_btFB) return;
	_btFB.addEventListener('click', function(e){
		e.preventDefault()
		var metaTitle = document.querySelector("meta[property='og:title']").getAttribute("content")
		,	metaURL = document.querySelector("meta[property='og:url']").getAttribute("content")
		,	metaImg = document.querySelector("meta[property='og:image']").getAttribute("content")
		,	metaDesc = document.querySelector("meta[property='og:description']").getAttribute("content")
		
		FB.ui({
		  method: 'feed',
		  name: metaTitle,
		  link: metaURL,
		  picture: metaImg,
		  description: metaDesc
		  //caption: metaDesc,
		}, function(response){
			console.log(response)
		});
	})

}
)()
