var pubsub = require('pubsub');
var classList = require('dom-classlist');
var gsap = require('gsap');


module.exports = function () {
	var _filterForm = document.querySelector('#filtersForm')
	if(!_filterForm) return;
	var _filters = _filterForm.querySelectorAll('label');
	var _inputs = _filterForm.querySelectorAll('input.fake-radio')
	
	var timeline;
	var el;
	

	init();
	addHandlers();

	function init(){
		
		//OPEN FORM
		
		el = document.querySelectorAll('#filtersForm ul.list');

		Array.prototype.forEach.call(el, function(e) {
			e.open = 0;
			timeline = new TimelineLite({paused: true});
			timeline.add( TweenMax.to(e, 0.4, {
				height: 0,
				opacity : 0,
				display:'none'
			}));
			e.anim = timeline;
			e.anim.play();
			
			setTimeout(function(){
				TweenMax.set(e, {
					visibility: 'visible',
				});
			}, 100);
		});


		
	}

	function addHandlers() {

		

		var titles = document.querySelectorAll('.filters_attribute-title');
		
		Array.prototype.forEach.call(titles, function(title) {
			title.addEventListener('click', function(){ 

				if(title.parentNode.querySelector('ul.list').open){
					//CLOSE OTHER
					Array.prototype.forEach.call(el, function(title) {
						title.parentNode.querySelector('ul.list').anim.play();	
						title.parentNode.querySelector('ul.list').open = false;
					}); 
				}else{
					
					//CLOSE OTHER
					Array.prototype.forEach.call(el, function(title) {
						let list = title.parentNode.querySelector('ul.list');
						list.anim.play();	
						list.open = false;
					}); 

					let list = title.parentNode.querySelector('ul.list');
					
					//MANAGE CURRENT
					
					if(!list.open){
						list.anim.reverse();
						list.open = true;

						//CLOSE CLICK OUTSIDE
						setTimeout(function(){
							document.addEventListener('click', event => {
								const isClickInside = list.parentNode.contains(event.target)
 
								if (!isClickInside) {
									list.anim.play();	
									list.open = false;
								}
							})
						}, 0)
						
						return;
					}

					

					
				}

						});
					});
		

		Array.prototype.forEach.call(_filters, function(el) {
			el.addEventListener('click', clickFilter)
		})
		
	}

	function clickFilter(e) {
		this.parentNode.parentNode.anim.play();  
		this.parentNode.parentNode.open = false; 
		pubsub.emit('load:getStatus', function (isLoading){
			if(isLoading){
				e.preventDefault()
			} else{
				
				var _labelClicked = e.target
				,	_relatedInput = _labelClicked.parentNode.querySelector('input')
				//comportement de radiobutton pour les onglets mediatheque
				if(classList(_relatedInput).contains('fake-radio')){
					var _fakeRadios = _inputs;
					Array.prototype.forEach.call(_fakeRadios, function(el) {
						if(el !== _relatedInput){
							 el.checked = false;
							 TweenMax.to(el.parentNode.parentNode.parentNode.querySelector('.filters_attribute-title'),0.3,{opacity:0.25});
						}
					})
				} 

				// timeout pour serialize form OK
				setTimeout(function(){ 
					 
					if(_relatedInput.checked){
						TweenMax.to(_relatedInput.parentNode.parentNode.parentNode.querySelector('.filters_attribute-title'),0.3,{opacity:1});
					}else{
						TweenMax.to(_relatedInput.parentNode.parentNode.parentNode.querySelector('.filters_attribute-title'),0.3,{opacity:0.25});
					}
					pubsub.emit('load:more', true);
					

				}, 200)
				
			}
		});
		
	} 
}
