var pubsub = require('pubsub');
var gsap = require('gsap');


module.exports = function () {
	var _displayForm = document.querySelector('.liste-gen_displayer')
	if(!_displayForm) return;
	var _radioBts = _displayForm.querySelectorAll('label')

	addHandlers()

	function addHandlers() {
		Array.prototype.forEach.call(_radioBts, function(el) {
			el.addEventListener('click', clickRadioBt)
		})
	}

	function clickRadioBt(e) {
		pubsub.emit('load:getStatus', function (isLoading){
			if(isLoading){
				e.preventDefault()
			} else{
				// timeout pour serialize form OK
				setTimeout(function(){
					pubsub.emit('load:more', true)
				}, 200)
				
			}
		});
		
	} 
}
