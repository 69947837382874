var pubsub = require("pubsub");
var gsap = require("gsap");
var Swiper = require("swiper");

module.exports = function () {

  var _swipers = document.querySelectorAll(".swiper-container:not(.vignette):not(.singleLine):not(.hero)");

  Array.prototype.forEach.call(_swipers, function (el) {
    var mySwiper = new Swiper(el, {
      // Optional parameters
      direction: "horizontal",
      loop: true,
      threshold: 50,
      speed: 1000,
      autoplay: 6000,
      // If we need pagination
      pagination: el.querySelector(".swiper-pagination"),
      paginationClickable: true,

      // Navigation arrows
      nextButton: ".swiper-button-next",
      prevButton: ".swiper-button-prev",
      passiveListeners: true,
      // And if we need scrollbar
      //scrollbar: '.swiper-scrollbar',
    });

    el.onmouseover = function () {
      //pause autoplay
      mySwiper.stopAutoplay();
    };

    el.onmouseout = function () {
      //play autoplay
      mySwiper.startAutoplay();
    };
  });

  var _swipersVignette = document.querySelectorAll(
    ".swiper-container.vignette"
  );

  Array.prototype.forEach.call(_swipersVignette, function(el) {
    var mySwiper2 = new Swiper(el, {
      direction: "horizontal",
      slidesPerColumn: 2,
      slidesPerView: 3.5,
      spaceBetween: 30,
      observer: true,
      observeParents: true,
	    nextButton: ".swiper-button-next",
      prevButton: ".swiper-button-prev",
      breakpoints: {
        640: {
          slidesPerView: 1.3,
          spaceBetween: 30
        }
      }
      
    });
  });

  var _swipersSingleLine = document.querySelectorAll(
    ".swiper-container.singleLine"
  );

  Array.prototype.forEach.call(_swipersSingleLine, function(el) {
    var mySwiper3 = new Swiper(el, {
      direction: "horizontal",
      slidesPerView: 3.5,
      spaceBetween: 30,
      observer: true,
      observeParents: true,
	    nextButton: ".swiper-button-next",
      prevButton: ".swiper-button-prev",
      breakpoints: {
        640: {
          slidesPerView: 1.3,
          spaceBetween: 30
        }
      }
    });
  });

  let _hero = document.querySelector(".swiper-container.hero");
  let swiperHero = new Swiper(_hero, {
    // Optional parameters
    direction: "horizontal",
    loop: true,
    threshold: 50,
    speed: 1000,
    autoplay: 12000,
    // If we need pagination
    pagination:_hero.querySelector(".swiper-pagination"),
    paginationClickable: true,

    // Navigation arrows
    nextButton: ".swiper-button-next",
    prevButton: ".swiper-button-prev",
    passiveListeners: true,
    onTransitionStart: function(swiper) {
      pauseAllVideos(swiper.slides, swiper.activeIndex)
      let activeSlide = swiper.slides[swiper.activeIndex]
      let activeSlideContent = activeSlide.getElementsByTagName('video')[0]
      if(activeSlideContent) {
        activeSlideContent.play()
      }
    },
    onSlideChangeEnd: function(swiper) {
    }
  });

  _hero.onmouseover = function () {
    //pause autoplay
    swiperHero.stopAutoplay();
  };

  _hero.onmouseout = function () {
    //play autoplay
    swiperHero.startAutoplay();
  };

  function pauseAllVideos(swiper, active) {
    let slides = Array.from(swiper)
    for (const slide of slides) {
      if(slide.index !== active) {
        let slideContent = slide.getElementsByTagName('video')[0]
        if(slideContent) {
          console.log("here")
          slideContent.pause()
        }
      }
    }
  }

};
