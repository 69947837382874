var classList = require('dom-classlist');
var gsap = require('gsap');
var pubsub = require('pubsub');
var serialize = require('form-serialize');
var xhr = require('xhr');

var Filters = require('partials/list-filters');
var DisplayMode = require('partials/list-displayMode');


module.exports = function () {
	var _listGenSection = document.querySelector('.liste-gen.wrapper')
	if(!_listGenSection) return;

	var _listGenContainer = _listGenSection.querySelector('.liste-gen_container')
	,	_btLoadMore = _listGenSection.querySelector('.bt.load-more')
	,	_filterForm = document.querySelector('#filtersForm')
	,	_displayForm = _listGenSection.querySelector('.liste-gen_displayer')
	,	_loader = _listGenSection.querySelector('.liste-gen_loader')
	//,	_btList =  _listGenSection.querySelector('.liste-gen_displayer .icon-list')
	//,	_btGrid =  _listGenSection.querySelector('.liste-gen_displayer .icon-grid')
	,	requestURL = _listGenSection.getAttribute('data-url')
	//,	displayMode = classList(_btList).contains('active') ? 'list' : 'grid'
	, to
	var pagination = 0
	,   nbPages = 1
	,   currentPage = 0
	,	isLoading = false

	if(_btLoadMore) {
	    pagination = parseInt(_btLoadMore.getAttribute('data-pagination'))
	    nbPages = parseInt(_btLoadMore.getAttribute('data-nbpages'))
	    if(nbPages <= 1) TweenMax.to(_btLoadMore, 0, {autoAlpha: 0})
	}

	if(_filterForm) Filters();
	if(_displayForm) DisplayMode();

	addHandlers()

	function addHandlers() {
		pubsub.on('load:getStatus', getLoadingStatus)
		pubsub.on('load:more', loadItems)
		if(_btLoadMore) _btLoadMore.addEventListener('click', clickLoadMore)
		//if(_btList) _btList.addEventListener('click', clickDisplayMode)
		//if(_btGrid) _btGrid.addEventListener('click', clickDisplayMode)
		 pubsub.on('resize', debounceResize)
	}

	function debounceResize(){
		clearTimeout(to);
		to = setTimeout(function(){
			//harmonizeHeight(_listGenContainer.querySelectorAll('.liste-gen_item, .liste-gen_gridItem'))
			harmonizeHeight(_listGenContainer.querySelectorAll('.liste-gen_gridItem'))
		}, 300)
	}
	
	function clickLoadMore(e) {
		e.preventDefault();
		pubsub.emit('load:more', false)
	}

	/*function clickDisplayMode(){
		pubsub.emit('load:more', true)
	}*/
	/*
	function clickBtList(){
		if(classList(_btList).contains('active')) return;
		classList(_btList).add('active')
		classList(_btGrid).remove('active')
	}
	function clickBtGrid(){
		/*if(classList(_btGrid).contains('active')) return;
		classList(_btGrid).add('active')
		classList(_btList).remove('active')
	}
	*/
	function getLoadingStatus(fn){
		return fn(isLoading);
	}

	function loadItems(clearPreviousList) {
		if(isLoading) return;
		isLoading = true;

		if(clearPreviousList){
			//_listGenContainer.innerHTML = '';
			_btLoadMore.setAttribute('data-currentpage', 0)
		}
	    var currentUrl = requestURL//document.location.href
	    ,   reqContent = _filterForm ? serialize(_filterForm) +'&' : '';
	    reqContent += _displayForm ? serialize(_displayForm) +'&' : '';
	    ;
	    reqContent += 'todo=filter';
	    //console.log(reqContent);

	    currentPage = parseInt(_btLoadMore.getAttribute('data-currentpage'))
	    currentPage ++;
	    currentUrl+=("page-"+currentPage+"/");
	    TweenMax.to(_btLoadMore, .2, {autoAlpha:0, onComplete: function(){
	        //_loaderMoreArticles.classList.add('active')
	        if(clearPreviousList) _listGenContainer.innerHTML = '';
	        classList(_loader).add('active');
	        xhr({
	                url: currentUrl
	            ,   headers:{"Content-Type": "application/x-www-form-urlencoded"}
	            ,   method:'POST'
	            ,   body: reqContent
	            }
	            ,   function (err, resp, body) {
	                    if(err == null){
	                    	isLoading = false;
	                        var JSONbody = JSON.parse(body)[0];
	                        // count results and compare to pagination
	                        var dummy = document.createElement('div');
	                        dummy.innerHTML = JSONbody.msg;
	                        var feedbackItems = dummy.querySelectorAll('.liste-gen_item, .liste-gen_gridItem');
	                         if(feedbackItems.length > 0){
	                        	nbPages = parseInt(feedbackItems[0].getAttribute('data-nbpages'))
	                        	_btLoadMore.setAttribute('data-nbpages', nbPages);
	                        }
	                        if(currentPage == nbPages){
	                        	// here we are on the last page so we break the routine and don't display the load more button
	                        } else if(feedbackItems.length>=pagination && pagination > 0){
	                        	TweenMax.to(_btLoadMore, .2, {autoAlpha: 1})
	                        } else if(feedbackItems.length<=0){
	                        	//_listGenContainer.innerHTML = '<div class="no-result">:(</div>'
	                        }

	                        classList(_loader).remove('active');
	                        _listGenContainer.innerHTML += JSONbody.msg;
	                        _btLoadMore.setAttribute('data-currentpage', currentPage);
	                        //harmonizeHeight(_listGenContainer.querySelectorAll('.liste-gen_item, .liste-gen_gridItem'))
	                        harmonizeHeight(_listGenContainer.querySelectorAll('.liste-gen_gridItem'))
	                      
	                    } else {
	                        //_loaderMoreArticles.classList.remove('active')
	                        console.log(err);
	                    }
	                }
	        )
	    }})
	    
	}

	function harmonizeHeight(items){
		var maxH = 0;
		Array.prototype.forEach.call(items, function(el, i){
			el.style.minHeight = 'auto';
			maxH = Math.max(maxH, el.offsetHeight)
		})
		Array.prototype.forEach.call(items, function(el, i){
			el.style.minHeight = maxH + 'px';
		})
	}
}
