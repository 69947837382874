var classList = require('dom-classlist');
var gsap = require('gsap');
var pubsub = require('pubsub');
var serialize = require('form-serialize');
var xhr = require('xhr');


module.exports = function () {
	
	var _contactForm = document.querySelector('#contact-form')
	if(!_contactForm) return;

	var _btSubmit = _contactForm.querySelector('.bt')
	,	_inputs = _contactForm.querySelectorAll('input[type="text"], textarea')
	,	_confirmField = _contactForm.querySelector('.confirm')
	,	isLoading = false
	,	to

	addHandlers()

	function addHandlers() {
		_btSubmit.addEventListener('click', clickSubmit)
	}
	
	function clickSubmit(e) {
		e.preventDefault();
		if(classList(_btSubmit).contains('ok')) return;
		submitForm();
	}

	

	function submitForm() {
		if(isLoading) return;
		isLoading = true;

		classList(_btSubmit).remove('ok')
		classList(_btSubmit).remove('ko')
		classList(_confirmField).remove('active')
		Array.prototype.forEach.call(_inputs, function(input){
			classList(input).remove('error')
		})
		
	    var currentUrl = document.location.href
	    ,   reqContent = serialize(_contactForm) +'&';
	    ;
	    reqContent += 'todo=contact';
	    //console.log(reqContent);
	    TweenMax.to(_btSubmit, .2, {autoAlpha:0, onComplete: function(){
	       
	        xhr({
	                url: currentUrl
	            ,   headers:{"Content-Type": "application/x-www-form-urlencoded"}
	            ,   method:'POST'
	            ,   body: reqContent
	            }
	            ,   function (err, resp, body) {
	                    if(err == null){
	                    	isLoading = false;
	                        var JSONbody = JSON.parse(body);
	                        Array.prototype.forEach.call(JSONbody, function(el, i){
								if(el.level == 'error'){
		                        	classList(_contactForm.querySelector('#'+el.field)).add('error')
		                        	classList(_btSubmit).add('ko');
		                        	_confirmField.innerHTML = el.msg;
		                        	clearTimeout(to)
		                        	to = setTimeout(function(){classList(_btSubmit).remove('ko');}, 1500)
		                        } else {
		                        	classList(_btSubmit).add('ok')
		                        	if(el.field == "btn")_btSubmit.setAttribute("value", el.msg)
		                        	if(el.field == "msg")_confirmField.innerHTML = el.msg;
			                        Array.prototype.forEach.call(_inputs, function(input){
			                        	input.disabled = true;
			                        	input.readOnly = true;
			                        })
		                        }
		                        
	                        });                        
	                        
	                        classList(_confirmField).add('active')
	                        TweenMax.to(_btSubmit, .2, {autoAlpha: 1})
	                        classList(_confirmField).add('active')


	                    } else {
	                        //_loaderMoreArticles.classList.remove('active')
	                        console.log(err);
	                    }
	                }
	        )
	    }})
	    
	}
}
