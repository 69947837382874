var pubsub = require('pubsub');
var gsap = require('gsap');
var classList = require('dom-classlist');
var Swiper = require('swiper');

module.exports = function () {
	var _lightbox = document.querySelector('.lightbox')
	,	_container = _lightbox.querySelector('.content')
	,	_swiperWrapper = _container.querySelector('.swiper-wrapper')
	,	_btClose = _lightbox.querySelector('.bt-close')
	, 	isOpened = false
	,	to_debounce
	,	to_txt
	,	margin = window.innerWidth >= 768 ? 100 : 20
	,	toDo
	,	mediaSwiper
	,	slided = false
	;

	pubsub.on('lightbox:loadContent', loadContent)
	pubsub.on('lightbox:close', close)

	TweenMax.to(_lightbox, 0, {autoAlpha:0})
	TweenMax.to(_container, 0, {autoAlpha:0})

/*	_btClose.addEventListener('click', function(){
		if(isOpened) pubsub.emit('lightbox:close');
	})*/

	// fermeture au click sur fond alpha
	/*_container.addEventListener('click', function(e){
		e.stopPropagation();
	})
	*/
	
	_lightbox.addEventListener('click', function(e){
		if(isOpened) pubsub.emit('lightbox:close');
		e.stopPropagation()
	});
	_lightbox.addEventListener('touchmove', function(e){
		e.stopPropagation()
		e.preventDefault()
	})
	//
	document.addEventListener('keydown',function(e) {
	    // ESCAPE key pressed
	    if (e.keyCode == 27) {
	        pubsub.emit('lightbox:close');
	    }
	});

	_lightbox.querySelector('.swiper-button-prev').addEventListener('click', function(e){
		e.stopPropagation()
	})
	_lightbox.querySelector('.swiper-button-next').addEventListener('click', function(e){
		e.stopPropagation()
	})

	function loadContent(index, mediasCollection ){

			open();
			for(var i=0; i< mediasCollection.length; i++){
				var media = mediasCollection[i]
				,	slide = document.createElement('div')
				;
				slide.className = 'swiper-slide';
				slide.setAttribute('data-type', media.type)
				if(media.type == 'image'){
					var img = new Image();
					img.onload = function() {
						
						//openContent();
					};
					img.src = media.data;
					//img.className = "lb-img";
					var div = document.createElement('div')
					div.className = "lb-img";
					div.style.backgroundImage = 'url("'+media.data+'")';
					slide.appendChild(div);
					
				} else if(media.type == 'video'){
					var id= getYoutubeID(media.data)
					,	iframe ='<iframe width="100%" height="100%" src="https://www.youtube.com/embed/'+id+'" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>'
					;
					slide.innerHTML = media.data;
					//setTimeout(openContent, 500);
				}
				_swiperWrapper.appendChild(slide)
			}

			var _slides = _swiperWrapper.querySelectorAll('.swiper-slide')

			mediaSwiper = new Swiper (_container, {
			    // Optional parameters
			    direction: 'horizontal',
		//	    loop: true,
			    slidesPerView:'auto',
			    // If we need pagination
			    //pagination: '.swiper-pagination',
			    threshold: 30,
			    spaceBetween: 50,
			    // Navigation arrows
			    nextButton: '.swiper-button-next',
			    prevButton: '.swiper-button-prev',
			    
			    // And if we need scrollbar
			    //scrollbar: '.swiper-scrollbar',
			})
			if(index == 0){
				setTimeout(openContent, 500);
			} else if(!slided){
				slided = true;
				mediaSwiper.onSlideChangeEnd = setTimeout(openContent, 500);
				mediaSwiper.slideTo(index)
			}
			
			

	}

	function getYoutubeID(url){
		var ID = '';
		  url = url.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
		  if(url[2] !== undefined) {
		    ID = url[2].split(/[^0-9a-z_\-]/i);
		    ID = ID[0];
		  }
		  else {
		    ID = url;
		  }
		  return ID;
	}
 
	function clearContent(){
		mediaSwiper.destroy(true, true);
		_swiperWrapper.innerHTML = "";
		classList(_lightbox).add('loading')
	}

	function open(){
		pubsub.emit('body:noscroll')
		TweenMax.to(_lightbox, .3, {autoAlpha:1})
	}

	function openContent(){
		TweenMax.to(_container, .3, {autoAlpha:1})
		classList(_lightbox).remove('loading')
		isOpened = true;
	}

	function close(){
		pubsub.emit('body:scroll')
		TweenMax.to(_container, .3, {autoAlpha:0, onComplete:clearContent})
		TweenMax.to(_lightbox, .3, {autoAlpha:0, delay: .3})
		isOpened = false;
		slided = false;
	}
}
