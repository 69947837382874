var classList = require('dom-classlist');
var pubsub = require('pubsub');
// global object
window.G = window.G || {}
G._html = document.querySelector('html')
G.isMobile = classList(G._html).contains('mobile')
G.isTablet = classList(G._html).contains('tablet')
//
var ExpandableMenu = require('partials/expandable-menu');
var mainnav = require('partials/mainnav');
var Contact = require('partials/contact');
var Newsletter = require('partials/newsletter');
var Geoloc = require('partials/geoloc');
var Lightbox = require('partials/lightbox');
var List = require('partials/list-pagination');
var Loader = require('partials/loader');
var MediaGallery = require('partials/media-gallery');
var ScrollWatcher = require('partials/scroll-watcher');
//var Search = require('partials/search');
var Share = require('partials/share');
var Swiper = require('partials/swiper');
var YoutubeAPI = require('partials/youtube');
var SliderSwipe = require('partials/slider-player');
//
var _body = document.querySelector('body')

window.onYouTubeIframeAPIReady = function() {
	YoutubeAPI()
}

document.addEventListener("DOMContentLoaded", function(){
   pubsub.emit('document:ready')
   var _body = document.querySelector('body')
    var cookieMsgManager = function() {
        var cookieA = document.querySelector('.cookie-alert');
        if(cookieA){
            _body.addEventListener('click',function(){
                if(cookieA){
                    cookieA.style.transform = 'translateY(100%)';
                    setTimeout(function(){
                        cookieA.parentNode.removeChild(cookieA);
                        cookieA = 0; 
                    }, 400);
                    
                    var today = new Date();
                    var expire = new Date();
                    expire.setTime(today.getTime() + 3600000*24*365);
    
                    document.cookie = "cookieAlert="+escape('miam')
                                    + ";expires="+expire.toGMTString();
                }
            })
    
            window.addEventListener('scroll',function(){
                if(cookieA){
                    cookieA.style.transform = 'translateY(100%)';
                    setTimeout(function(){
                        cookieA.parentNode.removeChild(cookieA);
                        cookieA = 0;
                    }, 400);
                    var today = new Date();
                    var expire = new Date();
                    expire.setTime(today.getTime() + 3600000*24*365);
    
                    document.cookie = "cookieAlert="+escape('miam')
                                    + ";expires="+expire.toGMTString();
                }
            })
        }
    }
    cookieMsgManager();
});

pubsub.on('body:noscroll', function(){
	classList(_body).add('no-scroll')
})
pubsub.on('body:scroll', function(){
	classList(_body).remove('no-scroll')
})


// Only native resize listener on site
window.addEventListener('resize', resize, false);

// Can be forced from any
pubsub.on('global-resize', resize);

// Global resize
function resize() {
    pubsub.emit('resize', window.innerWidth, window.innerHeight);
}


function initOnce(){
	Lightbox();
	ScrollWatcher()
    SliderSwipe()
	//Search();
	var _expMenus = document.querySelectorAll('.expandable-menu');
	Array.prototype.forEach.call(_expMenus, function(el) {
		var em = ExpandableMenu(el);
	})
}

function recursiveInit(){
	Loader()
	Contact();
	Newsletter()
	Geoloc()
	List();
	MediaGallery();
	Swiper();
}


pubsub.on('document:ready', function(){
	initOnce();
	recursiveInit()
})