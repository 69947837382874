var pubsub = require('pubsub');
var gsap = require('gsap');


module.exports = function () {
	var _galleryItems = document.querySelectorAll('.media_list_item, [data-js-trigger="lightbox"]')
	,	mediasCollection = []
	,	 counter = 0
	Array.prototype.forEach.call(_galleryItems, function(el){
		var _item = el
		,	media = {}
		;
		media.type = _item.getAttribute('data-type');
		media.data = _item.getAttribute('data-media');
		mediasCollection.push(media);
		_item.index = counter;
		_item.addEventListener('click', clickItem);
		counter ++;
	})

	function clickItem(e){
		e.preventDefault();
		
		var _item = this
		,	index = this.index
		,	data = _item.getAttribute('data-media')
		,	type = _item.getAttribute('data-type')
		;
		//pubsub.emit('lightbox:loadContent', data, type);
		pubsub.emit('lightbox:loadContent', index, mediasCollection);
		
	}
}
