var gsap = require('gsap');
var pubsub 	= require('pubsub');
var classList = require('dom-classlist');

module.exports = function (item) {
	var _expMenu = item
	,	_triggerLink = _expMenu.querySelector('.expandable-menu__trigger-link')
	,	_triggerIcon = _triggerLink.querySelector('.expandable-menu__trigger-link__icon')
	,	_label = _expMenu.querySelector('.label')
	,	_list = _expMenu.querySelector('.expandable-menu__list')
	,	_listItems = _list.querySelectorAll('li')
	,	isOpened = false
	,	timeline
	;

	initTimeline()
	addHandlers()


	function initTimeline(){
		timeline = new TimelineLite({paused: true});
		timeline.add( TweenMax.to(_triggerIcon, 0.2, {
			autoAlpha: .2
		}));

		timeline.add( TweenMax.staggerTo(_listItems, 0.2, {
			autoAlpha: 1,
			y: -20,
			ease: Power2.easeOut
		}, 0.1 ), "-=0.1");
	}

	function addHandlers(){
		pubsub.on('resize', handleResize);
		_triggerLink.addEventListener('click', clickTrigger)
		_label.addEventListener('click', clickTrigger)
		//_expMenu.addEventListener('mouseover', function(){closeTimeline(); classList(_expMenu).remove('opened')})
	}

	function clickTrigger(e){
		e.preventDefault();
		if(!isOpened) openTimeline();
			else closeTimeline();

		classList(_expMenu).toggle('opened')
	}

	function openTimeline(){
		isOpened = true;
		timeline.play();
	}
	function closeTimeline(){
		isOpened = false;
		timeline.reverse();
	}

	function handleResize() {
		timeline.reverse();
	}
}