
module.exports = function () { 
	if(!document.querySelector('#map-canvas')) return;
	var _map = document.querySelector('#map-canvas')
	,	lat = parseFloat(_map.getAttribute('data-lat'))
	,	lng = parseFloat(_map.getAttribute('data-lng'))
	,	zoom = parseFloat(_map.getAttribute('data-zoom'))
	,	viewport = _map.getAttribute('data-vp')
	,	tabLatLng = viewport.split(',');
	var SW = new google.maps.LatLng(tabLatLng[0], tabLatLng[1])
	,	NE = new google.maps.LatLng(tabLatLng[2], tabLatLng[3])
	var bounds = new google.maps.LatLngBounds()
	bounds.extend(SW);
	bounds.extend(NE);
	//function initialize() {
		var stylez = [
		    {
		      featureType: "all",
		      elementType: "all",
		      stylers: [
		        { saturation: -100 } // <-- THIS
		      ]
		    }
		];

		var mapOptions = {
			center: { lat: lat, lng: lng},
			zoom: zoom || 9,
			scrollwheel: false,
			draggable: !G.isMobile,
			//disableDefaultUI: true,
			/*zoomControl: true,
		    zoomControlOptions: {
		        style: google.maps.ZoomControlStyle.SMALL,
		        position: google.maps.ControlPosition.BOTTOM_RIGHT
		    },*/
			mapTypeControl: false,
		    mapTypeControlOptions: {
		        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
		        position: google.maps.ControlPosition.BOTTOM_RIGHT,
		        mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'tehgrayz']
		    },
		    panControl: false,
		    panControlOptions: {
		        position: google.maps.ControlPosition.BOTTOM_LEFT
		    },
		    zoomControl: true,
		    zoomControlOptions: {
		        style: google.maps.ZoomControlStyle.SMALL,
		        position: google.maps.ControlPosition.RIGHT_BOTTOM
		    },
		    scaleControl: false,  // fixed to BOTTOM_RIGHT
		    streetViewControl: true,
		    streetViewControlOptions: {
		        position: google.maps.ControlPosition.RIGHT_BOTTOM
		    }
		};
		
		var map = new google.maps.Map(document.getElementById('map-canvas'),
		mapOptions);
		var mapType = new google.maps.StyledMapType(stylez, { name:"Grayscale" });    
		map.mapTypes.set('tehgrayz', mapType);
		map.setMapTypeId('tehgrayz');
		
		/*var imgMarker = "assets/images/marker-gmap.png";
		var marker = new google.maps.Marker({
		    position: map.getCenter(),
		    map: map,
		    icon: imgMarker,
		    title: ''
		});*/

		
		var myIcon = new google.maps.MarkerImage(WEB_ROOT_IMAGES+"marker.png", null, null, null, new google.maps.Size(26,35));
		
		var marker = new google.maps.Marker({
			position: map.getCenter(),
			map: map,
			flat: true,
			title: '',
			icon: myIcon,
			//shadow: WEB_ROOT_IMAGES+'markershadow-gmap.png'
		});
		//map.fitBounds(bounds);
		/*var markerNE = new google.maps.Marker({
			position: NE,
			map: map,
			flat: true,
			title: '',
			icon: myIcon,
			 title: 'NORTH EST'
			//shadow: WEB_ROOT_IMAGES+'markershadow-gmap.png'
		});
		var markerSW = new google.maps.Marker({
			position: SW,
			map: map,
			flat: true,
			title: '',
			icon: myIcon,
			title: 'SOUTH WEST'
			//shadow: WEB_ROOT_IMAGES+'markershadow-gmap.png'
		});*/

		/*var contentString = '<div id="content">'+
		      '<div id="siteNotice">'+
		      '</div>'+
		      '<h1 id="firstHeading" class="firstHeading"><div class="main-logo"></div></h1>'+
		      '<div id="bodyContent">'+
		      '<p>Hent Kerbader,<br/>29170 Fouesnant<br/>France</p>' +
		      '<p><b>Tel.</b> +33 2 98 56 14 44</p>' +
		      '</div>'+
		      '</div>';

		  var infowindow = new google.maps.InfoWindow({
		    content: contentString
		  });
		  infowindow.open(map, marker);*/
		  /*marker.addListener('click', function() {
		      infowindow.open(map, marker);
		  });*/

	//}

	//google.maps.event.addDomListener(window, 'load', initialize);	
}